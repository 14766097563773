






























































































































































































































































































































































































































































































































































































































































































































































































import { Api, PhonicsScreeningQuestion } from '@/edshed-common/api'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class PhonicsScreening extends Vue {
  pools: Object | null = null
  data: PhonicsScreeningQuestion[] = []
  activeTab: 0 | 1 | 2 = 0

  async mounted () {
    this.pools = await Api.getWordPools()
  }

  async generatePhonicsData () {
    this.data = await Api.generateScreeningCheckData()
    this.activeTab = 0
  }

  async refreshClicked () {
    this.data = await Api.generateScreeningCheckData()
  }
}
